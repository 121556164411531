<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import iFrameResize from 'iframe-resizer/js/iframeResizer.contentWindow'

export default {
    name: 'Selector',
    directives: {
        'iframe-resize': {
            beforeMount() {
                iFrameResize(
                    { targetOrigin: 'https://www-themagazineshop-com.dsb-ebiz.com/'}
                )
            }
        }
    }
}
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

$fontPath: "./assets/fonts";
/* oswald-regular - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('#{$fontPath}/oswald-v47-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fontPath}/oswald-v47-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontPath}/oswald-v47-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-300.woff') format('woff'), /* Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontPath}/oswald-v47-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-500 - Medium */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: url('#{$fontPath}/oswald-v47-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fontPath}/oswald-v47-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontPath}/oswald-v47-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontPath}/oswald-v47-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-700 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fontPath}/oswald-v47-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fontPath}/oswald-v47-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontPath}/oswald-v47-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-700.woff') format('woff'), /* Modern Browsers */
    url('#{$fontPath}/oswald-v47-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontPath}/oswald-v47-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fontPath}/lato-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fontPath}/lato-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontPath}/lato-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fontPath}/lato-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$fontPath}/lato-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontPath}/lato-v22-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('#{$fontPath}/lato-v22-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('#{$fontPath}/lato-v22-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$fontPath}/lato-v22-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$fontPath}/lato-v22-latin-900.woff') format('woff'), /* Modern Browsers */
    url('#{$fontPath}/lato-v22-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$fontPath}/lato-v22-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

@layer base {
    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-serif;
        font-weight: 300;
    }

    .router-link-exact-active {
        > span {
            @apply bg-orange-600;
        }
    }

    .container {
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        max-width: 1024px !important;
        @apply mx-auto;
    }
}

@layer components {
    .btn-primary {
        @apply bg-primary hover:bg-primary-dark text-white px-6 py-3
    }

    .btn-primary-block {
        @apply btn-primary w-full
    }
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Countries } from "@/store";

@Component({
    computed: mapGetters(["getActiveRegion", "getRegionCountries", "getAllCountries"]),
})
export default class AddressFields extends Vue {
    @Prop() private ident!: string;

    @Prop({ default: false }) private onlyreq!: boolean;

    @Prop({ default: undefined }) private countries!: Countries|undefined;

    getRegionCountries!: Countries;
    getAllCountries!: Countries;

    public str(str: string): string {
        if (str.length) {
            return `${this.ident} __ ${str}`;
        }
        return this.ident;
    }

    get selectableCountries(): Countries {
        return this.countries ? this.countries : this.getRegionCountries;
    }

    public selectCountry($event: InputEvent) {
        const el = $event.target as HTMLSelectElement;
        if (el) {
            this.$emit("select-country", el.value);
        }
    }
}

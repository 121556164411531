import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/show-error",
        name: "Error",
        component: HomeView,
    },
    {
        path: "/thank-you",
        name: "Success",
        component: HomeView,
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;

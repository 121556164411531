






















































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
    computed: mapGetters(["getActiveProduct", "getActiveType", "getActiveRegion", "getRegionProducts", "getRegions"]),
    methods: {
        changeActiveProduct(string) {
            this.$store.dispatch("changeActiveProduct", string);
        },
        changeActiveRegion(event) {
            this.$store.dispatch("changeActiveRegion", event.target.value);
        },
    },
})
export default class ChoosePlan extends Vue {
    @Prop({ default: "" }) number!: string;
    @Prop({ default: "" }) css!: string;
}

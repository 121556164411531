interface GoogleTrackingData {
    event?: string;
    event_name?: string;
    event_info?: string;
    region?: string;
    offer?: string;
    value?: string | number;
    currency?: string;
}

export default class GoogleTracking {
    constructor() {}

    public sendDelregion(region: string) {
        this._sendData({
            region,
            event_info: "Choose your delivery region",
        });
    }

    public sendOffer(offer: string) {
        this._sendData({
            offer,
            event_info: "Choose your offer",
        });
    }

    public sendFormInit(region: string, offer: string) {
        this._sendData({
            region,
            offer,
            event_info: "Your Details - Initiated",
        });
    }

    public sendFormComplete(region: string, offer: string) {
        this._sendData({
            region,
            offer,
            event_info: "Your Details - Completed",
        });
    }

    public sendSuccessfulPayment(region: string, offer: string, value: string | number, currency: string) {
        this._sendData({
            region,
            offer,
            value,
            currency,
            event_info: "Successful Payment",
        });
    }

    private _sendData(data: GoogleTrackingData) {
        const message = Object.assign(
            {
                event: "autotrack",
                event_name: "AC Magazine Subscription",
            },
            data
        );
        console.log(message);
        window.parent.postMessage(message, "*"); // TODO: this should be secured, "*" needs to be replaced by the domain embedding this iframe, set in gitlab ci
    }
}

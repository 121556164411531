







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Error extends Vue {
    @Prop({ default: '' }) number!: string;
    @Prop({ default: '' }) css!: string;
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import "./assets/styles/tailwind.scss";
import GoogleTracking from "./google-tracking";

declare global {
    interface Window {
        googleTracking: GoogleTracking;
    }
}

window.googleTracking = window.googleTracking || new GoogleTracking();

Vue.config.productionTip = false;

Vue.use(VueScrollTo);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");




















































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import AddressFields from "@/components/AddressFields.vue";
import HiddenFormFields from "@/components/HiddenFormFields.vue";
import ChoosePlan from "@/components/ChoosePlan.vue";
import Thanks from "@/components/Thanks.vue";
import Error from "@/components/Error.vue";
import { mapState, mapGetters } from "vuex";
import { Countries, Product } from "@/store";

@Component({
    components: {
        ChoosePlan,
        HiddenFormFields,
        AddressFields,
        Thanks,
        Error,
    },

    computed: {
        ...mapState({
            activeProduct: "activeProduct",
        }),
        ...mapGetters({
            getActiveProduct: "getActiveProduct",
            getActiveRegion: "getActiveRegion",
            getRegionCountries: "getRegionCountries",
            getAllCountries: "getAllCountries",
        }),
    },
})
export default class HomeView extends Vue {
    $refs!: {
        form: HTMLFormElement;
    };

    public displayDiv = false;
    public sameAddress = true;
    public showOptOut = false;
    public isOpOutEi = false;
    public isOpOutTi = false;
    public isOpOutMi = false;
    public isOpOutSi = false;
    getActiveProduct!: Product;
    getRegionCountries!: Countries;
    getActiveRegion!: string;
    getAllCountries!: Countries;

    private billingCountry: string = "";

    get isError() {
        return this.$route.name === "Error" ? true : false;
    }
    get isSuccess() {
        return this.$route.name === "Success" ? true : false;
    }

    get forceDifferentAddress() {
        if (this.billingCountry && this.billingCountry.length > 0) {
            return !this.isBillingCountryInRegion;
        }
        return false;
    }

    get isBillingCountryInRegion() {
        return Object.keys(this.getRegionCountries).includes(this.billingCountry);
    }

    public selectBillingCountry(country: string) {
        this.billingCountry = country;
        if (!this.isBillingCountryInRegion) {
            this.sameAddress = false;
        }
    }

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.addEventListener(
                "input",
                () => {
                    if (typeof this.getActiveProduct["offerCode"] === "string") {
                        window.googleTracking.sendFormInit(this.getActiveRegion, this.getActiveProduct["offerCode"]);
                    }
                },
                { once: true }
            );

            const debounce = (context: any, fn: () => any, wait: number) => {
                let timer: number;
                return function (...args: []) {
                    if (timer) {
                        clearTimeout(timer); // clear any pre-existing timer
                    }
                    timer = setTimeout(() => {
                        fn.apply(context, args); // call the function if time expires
                    }, wait);
                };
            };

            const formComplete = debounce(
                this,
                () => {
                    if (this.$refs.form.checkValidity()) {
                        if (typeof this.getActiveProduct["offerCode"] === "string") {
                            window.googleTracking.sendFormComplete(this.getActiveRegion, this.getActiveProduct["offerCode"]);
                            localStorage.setItem("region", this.getActiveRegion);
                            localStorage.setItem("offerCode", this.getActiveProduct["offerCode"]);
                            localStorage.setItem("total", this.getActiveProduct["total"] + "");
                            localStorage.setItem("currency", this.getActiveProduct["currency"] as string);
                        }
                    }
                },
                300
            );

            this.$refs.form.addEventListener("input", () => {
                formComplete();
            });

            this.$watch("sameAddress", () => {
                formComplete();
            });

            this.$watch("getActiveProduct", (value) => {
                if (typeof value["offerCode"] === "string") {
                    window.googleTracking.sendOffer(value["offerCode"]);
                }
            });

            this.$watch("getActiveRegion", (value) => {
                window.googleTracking.sendDelregion(value);
            });
        }
        if (this.isSuccess && this.getActiveProduct && this.getActiveRegion) {
            if (typeof this.getActiveProduct["offerCode"] === "string") {
                const data = {
                    activeRegion: localStorage.getItem("region"),
                    offerCode: localStorage.getItem("offerCode"),
                    total: localStorage.getItem("total"),
                    currency: localStorage.getItem("currency"),
                };
                if (data.activeRegion && data.offerCode && data.total && data.currency) {
                    window.googleTracking.sendSuccessfulPayment(data.activeRegion, data.offerCode, data.total, data.currency);
                }
            }
        }
    }
}

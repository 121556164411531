import Vue from "vue";
import Vuex from "vuex";
import products from "./products.json";
import countries from "./countries.json";

Vue.use(Vuex);

interface Products {
    [key: string]: {
        [key: string]: Product;
    };
}

export interface Product {
    [key: string]: string | number;
}

export interface Countries {
    [key: string]: {
        [key: string]: string;
    };
}

export default new Vuex.Store({
    state: {
        activeRegion: "UK",
        activeMixeTyp: "bundle",
        products: products as Products,
        countryList: countries as Countries,
    },
    getters: {
        getActiveType: (state) => state.activeMixeTyp,
        getActiveRegion: (state) => state.activeRegion,
        getActiveProduct: (state) => {
            const activeProduct = state.products[state.activeRegion][state.activeMixeTyp];
            return activeProduct;
        },
        getRegionProducts: (state) => {
            const regionProducts = state.products[state.activeRegion];
            return regionProducts;
        },
        getRegions: (state) => {
            return Object.keys(state.products);
        },
        getRegionCountries: (state) => {
            const regionCountries = state.countryList[state.activeRegion];
            return regionCountries;
        },
        getAllCountries: (state, getters) => {
            let countries: { [key: string]: string } = {};
            for (const region of getters.getRegions) {
                countries = Object.assign(countries, state.countryList[region]);
            }
            countries = Object.values(countries)
                .sort()
                .reduce((sortedCountries, value) => {
                    const key = Object.keys(countries).find((key) => countries[key] === value);
                    if (key) {
                        sortedCountries[key] = countries[key];
                    }
                    return sortedCountries;
                }, {} as { [key: string]: string });
            return countries;
        },
    },
    mutations: {
        setActiveType: (state, payload) => {
            state.activeMixeTyp = payload;
        },
        setActiveRegion: (state, payload) => {
            state.activeRegion = payload;
        },
    },
    actions: {
        changeActiveProduct({ commit, getters }, payload) {
            commit("setActiveType", payload);
            // const offer = getters.getActiveProduct["offerCode"];
            // if (typeof offer === "string") {
            //     window.googleTracking.sendOffer(offer);
            // }
        },
        changeActiveRegion({ commit, getters }, payload) {
            commit("setActiveRegion", payload);
            // window.googleTracking.sendDelregion(payload);
            // // since activeProduct only changes the mixtype, this needs to be done when changing the activeRegion too
            // // another possibility would be to watch the getter?
            // const offer = getters.getActiveProduct["offerCode"];
            // if (typeof offer === "string") {
            //     window.googleTracking.sendOffer(offer);
            // }
        },
    },
    modules: {},
});
